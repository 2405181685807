import { lazy } from 'react'

export const PublicPath = [
	{
		path: '/login/:encoded/:type',
		exact: true,
		auth: false,
		component: lazy(() => import('../../pages/Login')),
	},
	{
		path: '/login-vif',
		exact: true,
		auth: false,
		component: lazy(() => import('../../pages/LoginVif')),
	},
	{
		path: '/404',
		exact: true,
		auth: false,
		component: lazy(() => import('../../pages/NotFound')),
	},
]
