import apiService from '../../apiService'
import { PATH } from './constants'

export const taskController = (configService) => {
	const service = apiService(configService)
	return {
		saveTask: (params) => {
			return service.post({
				url: PATH.TASK,
				body: { ...params },
			})
		},
		saveCancelTask: (params) => {
			return service.post({
				url: PATH.TASK_CANCEL,
				body: { ...params },
			})
		},
		getStatementOnlyCheckFq: (params) => {
			const { dates, money } = params
			return service.get({
				url: `https://taskbackend-fin-account.com/accounting/api/v1/statement/get-statement-only-check-fq/${dates}&${dates}&ALL&${money}`,
			})
		},
	}
}

// quo_num
// paybill (จ่ายปกติ,จ่ายวางบิล)
// whosend (ฟินแจ้ง)
// checksend (งานใหม่,งานต่ออายุ,งานโอนโค้ด)
