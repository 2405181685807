import styled from 'styled-components'
import { THEME } from '../../themes'

export const HeaderComponent = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
  border-bottom: 2px solid rgba(9, 30, 66, 0.08);
`
