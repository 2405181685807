import {
	getLocalStorage,
	setLocalStorage,
	removeLocalStorage,
} from './localStorage'
import { store } from '../../stores'
import { LOCAL_STORAGE } from '../constants'
import {
	authenAction,
	permissionsAction,
	profileAction,
	creditAction,
} from '../../actions'

export const setUserAuth = (data) => {
	return setLocalStorage(LOCAL_STORAGE.USER_AUTH, data)
}

export const getUserAuth = () => {
	return getLocalStorage(LOCAL_STORAGE.USER_AUTH)
}

// export const processLogin = async (data) => {
// 	await store.dispatch(authenAction({ status: true, user: data }))
// 	setUserAuth(data)
// }

export const processLogout = async () => {
	await store.dispatch(authenAction({ status: false }))
	await store.dispatch(
		permissionsAction({
			cuscode: '',
			name: '',
			status_permissions: null,
		})
	)
	await store.dispatch(
		profileAction({
			vif_type: '2',
		})
	)
	await store.dispatch(
		creditAction({
			creditBalance: 0,
			creditUse: 0,
			creditTotal: 0,
		})
	)
	removeLocalStorage(LOCAL_STORAGE.USER_AUTH)
}
